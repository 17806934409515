<template>
    <div>
		<el-row :gutter="10" class="mb8">
			<el-col :span="1.5">
				<el-button type="primary" icon="el-icon-plus" @click="handleAdd()" v-permission="'AGENT_COST_TEMPLATE_QUERY'">新增分润配置</el-button>
			</el-col>
		</el-row>

		<BenefitDialog :on-fresh="showCustomForm" @on-close="handlerCustomFormOnclose"></BenefitDialog>
	</div>
</template>

<script>
	import BenefitDialog from "@/components/benefitDialog/Index";
    export default {
        name: "ToolBar",
		components: {BenefitDialog},
		props: {
			dicts: {
				type: Object,
				default: {}
			}
		},
		data() {
			return {
				showCustomForm: false
			}
		},
		mounted() {
		},
		methods: {
			handlerCustomFormOnclose() {
				this.showCustomForm = false;
				this.commitChange();
			},
			handleAdd(){
				this.showCustomForm = true;
			},
			commitChange() {
				this.$emit('on-change');
			},
		}
    }
</script>

<style scoped>

</style>
